import { Paper, TableContainer } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom';

function DetailUser() {
    const { id } = useParams(); 
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div className='ml-5 flex-row justify-between '>
          
          <h1 className='font-bold text-2xl' >Liste des Utilisateurs</h1>
         
      </div>
      <TableContainer sx={{ maxHeight: 440 }}>
       
      </TableContainer>
     
   
    </Paper>
  )
}

export default DetailUser