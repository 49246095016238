import React from 'react'
import Box from '@mui/material/Box';
import Sidebar from '../Components/Sidebar';
import AppBars from '../Components/AppBar';
import CatTables from '../Components/CatTable';

function Categorie() {
  return (
    <>
        <AppBars />
        <Box height={70} />
        <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <CatTables />

        </Box>
            
        </Box>

    </>
  )
}

export default Categorie