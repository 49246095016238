import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { API_URL } from '../Config/config';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, set } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import AlertComponent from './Alert';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DialogContentText } from '@mui/material';





const columns = [
  { id: 'id', label: 'Id', minWidth: 170 },
  { id: 'photo', label: 'Photo', minWidth: 100 },
  { id: 'details', label: 'Details', minWidth: 100 },
  { id: 'actif', label: 'Actif', minWidth: 100 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
];


export default function TablePub() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [artistes, setArtistes] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    
    description: '',
   
    fileImage: null,
    
    // Add more fields as needed
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uploadSuccess = urlParams.get('uploadSuccess');

    if (uploadSuccess) {
      toast.success('Upload réussi');
      setTimeout(() => {
        // Retirez le paramètre de l'URL pour éviter d'afficher le message à chaque rechargement
        window.history.replaceState({}, document.title, window.location.pathname);
      }, 5000); // Disparaît après 5 secondes
    }
  }, []);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/listePub`);
        setData(response.data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching categories:', error);
      }finally {
        // Mettez isLoading à false une fois le chargement terminé
        setIsLoading(false);
    }
    };
  
    fetchCategories();
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (row) => {
    // Handle edit action
    navigate(`/dash`)
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

const handleSubmit = async () => {

  setTimeout(() => {
    setHasError(false);
    setErrorMessage("");
  }, 5000);

  setIsSubmitting(true); 
  try {
    const formData = new FormData();
    formData.append("image", formValues.fileImage);
    formData.append("details", formValues.description);

    await axios.post(`${API_URL}/createPub`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload réussi');
      handleCloseModal();
      // Redirigez avec le paramètre uploadSuccess=true
      window.location.href = `${window.location.href}?uploadSuccess=true`;
    
  } catch (error) {
    setHasError(true);
    setErrorMessage("Verifier les informations")
  }finally {
    setIsSubmitting(false); // Assurez-vous de mettre à jour l'état, que la soumission réussisse ou échoue
  }
};

  const handleFileChange = (file) => {
    setFormValues({
      ...formValues,
      fileImage: file,
    });
  };


  

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {hasError && <AlertComponent message={errorMessage} type="danger" />}
        <div className='ml-5 flex-row justify-between '>
          
          <h1 className='font-bold text-2xl' >Publicite</h1>
          <Button onClick={handleOpenModal} variant="contained">Ajouter une Pub </Button>
          <div className='mt-3' >
            <TextField
              label="Recherche"
              value={searchText}
              className='w-[300px] '
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <ToastContainer />
      <TableContainer sx={{ maxHeight: 440 }}>
        {isLoading && <div className='ml-10 mt-20 text-xl font-bold  ' >Chargement en cours...</div>}
        {!isLoading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left" // Align left for text columns
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {data
              .filter((row) =>
                Object.values(row).some(
                  (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
                )
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.email}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left"
                        className={
                          column.id === 'actif'
                            ? row.actif
                              ? 'text-green-500' // Green for active
                              : 'text-red-500' // Red for inactive
                            : ''
                        }
                      >
                        {column.id === 'photo' ? (
                          <img
                              src={`${API_URL}/uploads/${row.fichier}`}
                              alt={`Photo de ${row.id}`}
                              className='w-20 h-20 rounded-full'
                          />
                          ) : 
                          column.id === 'actions' ? (
                            <>
                              <IconButton style={{color: "blue"}} onClick={() => handleEdit(row)}>
                                <RemoveRedEyeIcon />
                              </IconButton>
                              <IconButton style={{color: "red"}} >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          ) : column.id === 'actif' ? (
                            row.actif ? 'Actif' : 'Inactif'
                          ) : (
                            row[column.id]
                          )}
                      </TableCell>
                      
                    ))}
                  </TableRow>
                );
              })
            }
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />


        {/* Modal for adding items */}
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Ajouter une publication </DialogTitle>
          <DialogContent>
            {/* Form elements */}

           

            <TextField
              label="Description"
              name="description"
              value={formValues.description}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          
              <div className='mt-3' >
                  <InputLabel htmlFor="image-input" shrink>
                      Veuillez sélectionner une image
                  </InputLabel>
                <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleFileChange(e.target.files[0])}
                  />
              </div>

          
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Annuler
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting} >
              {isSubmitting ? 'Enregistrement en cours...' : 'Ajouter'}
            </Button>
          </DialogActions>
      </Dialog>




    </Paper>
  );
}
