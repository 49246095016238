import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL } from '../Config/config';

const DetailsCat = () => {
  const { id } = useParams(); // Récupère l'ID de l'URL

  const [categoryDetails, setCategoryDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/catDetails/${id}`);
        // Assurez-vous que votre API renvoie les détails de la catégorie correctement sous forme de tableau
        setCategoryDetails(response.data);
        
      } catch (error) {
        console.error('Error fetching category details:', error);
      }
    };

    fetchData();
  }, [id]); // Mettez id comme dépendance pour refetch si l'ID change

  return (
    <div>
      <h2>Details de la Catégorie</h2>
      {/* Vérifiez si le tableau est vide et affichez un message en conséquence */}
      {categoryDetails.length === 0 ? (
        <p>Aucune information disponible pour cette catégorie.</p>
      ) : (
        // Mapping sur les détails de la catégorie pour les afficher
        categoryDetails.map((detail, index) => (
          <div key={index}>
            <p>Nom: {detail.titre}</p>
            {/* Ajoutez d'autres détails en fonction de votre modèle de données */}
          </div>
        ))
      )}
    </div>
  );
};

export default DetailsCat;
