import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/Boutton';
import Cover from '../img/admin.jpg';
import AlertComponent from '../Components/Alert';
import axios from 'axios';
import { API_URL } from '../Config/config';
import { set } from 'date-fns';


function Home() {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [Loading, setLoading] = useState(false);

  const handleLogin = async(event) => {
    event.preventDefault();
    
    const user = event.target.elements.user.value;
    const password = event.target.elements.password.value;
  
    console.log(`User: ${user}`);
    console.log(`Password: ${password}`);
    if (user || password) {
      
      setLoading(true)
      
      setTimeout(() => {
        setHasError(false);
        setErrorMessage("");
      }, 5000);


      try {
        const response = await axios.post(`${API_URL}/adminLogin`, {
          nom_utilisateur:user,
          password,

        });
        
        const data = response.data;

        if (response.status === 200) {
          console.log(data);
          localStorage.setItem('token', data.token);
          navigate('/dash');
        } 
      } catch (error) {
        console.log(error);
        setHasError(true);
        setErrorMessage("Erreur de connexion")
        

      }finally{
        setLoading(false)
      }




    }
  
    
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row items-center justify-center bg-[#edf6f9]">
      <CoverImage />
      
      <div className='w-full md:w-1/3 flex flex-col p-10 justify-between'>
        <div>
          {hasError && <AlertComponent message={errorMessage} type="danger" />}
        </div>
        <LoginTitle />

        <LoginInputs onLogin={handleLogin} loading={Loading} />

        
      </div>
     
    </div>
  );
}

const CoverImage = () => (
  <div className='relative w-full md:w-1/2 h-64 md:h-full flex flex-col'>
    <img src={Cover} className='w-full h-full object-cover' />
  </div>
);


const LoginTitle = () => (
  <div className='w-full flex flex-col bottom-10'>
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Connectez-vous à votre compte
          </h2>
        </div>
  </div>
);

const LoginInputs = ({ onLogin,loading }) => (
  

      <div className="max-w-md w-full space-y-8">
        <form className="mt-8 space-y-6" action="" onSubmit={onLogin} method="POST">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div className='mb-3' >
              <label htmlFor="utilisateur" className="sr-only">Nom utilisateur</label>
              <input id="utilisateur" name="user" type="text" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nom utilisateur" />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Mot de passe</label>
              <input id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Mot de passe" />
            </div>
          </div>

          <div className="flex items-center justify-between">
           
            <div className="text-sm">
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                Mot de passe oublié?
              </a>
            </div>
          </div>

          <div>
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {loading ? "Connexion en cours" : "Se connecter"}

            </button>
          </div>
        </form>
      </div>
);

export default Home;
