import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { API_URL } from '../Config/config';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AlertComponent from './Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DialogContentText, FormControlLabel, Switch, Typography } from '@mui/material';
import { useNavigate,Link } from 'react-router-dom';



const columns = [
  { id: 'nom_utilisateur', label: 'Nom Utilisateur', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'typeCompte', label: 'Type Compte', minWidth: 100 },
  { id: 'actif', label: 'Actif', minWidth: 100 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
];

// Adjust the createData function based on your actual data structure
function createData(nom_utilisateur, email, actif) {
  return { nom_utilisateur, email, actif };
}

export default function DataTables() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPass, setOpenModalPass] = useState(false);
  const [opensModal, setOpensModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [editedNom, setEditedNom] = React.useState('');
  const [editedEmail, setEditedEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = React.useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formValues, setFormValues] = useState({
    nom: '',
    email: '',
    pass:'',
    actif:null,
    selectedCompte: null,
    // Add more fields as needed
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uploadSuccess = urlParams.get('uploadSuccess');

    if (uploadSuccess) {
      toast.success('Operation effectue avec success');
      setTimeout(() => {
        // Retirez le paramètre de l'URL pour éviter d'afficher le message à chaque rechargement
        window.history.replaceState({}, document.title, window.location.pathname);
      }, 5000); // Disparaît après 5 secondes
    }
  }, []);


  useEffect(() => {
    // Fetch data from your API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/listeUser`);
        // Assuming your API returns an array of objects similar to your sample data
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Mettez isLoading à false une fois le chargement terminé
        setIsLoading(false);
    }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (row) => {
    setSelectedUser(row);
    setEditedNom(row.nom_utilisateur);
    setEditedEmail(row.email);

    setFormValues({
      actif: row.actif,
      // ... autres champs du formulaire
    });
    handleOpensModal();
  };

  //edit
  const handleOpensModal = () => {
    setOpensModal(true);
  };

  const handleClosesModal = () => {
    setOpensModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalPass = () => {
    setOpenModalPass(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModalPass = () => {
    setOpenModalPass(false);
  };

  const handleOkModal = () => {
    handleCloseModalPass();
    window.location.href = `${window.location.href}?uploadSuccess=true`;
  };

  const handleInputChange = (event) => {
    const { name, checked, value } = event.target;

  if (name === 'actif') {
    // Si l'événement provient du Switch, mettez à jour le state actif
    setFormValues({
      ...formValues,
      actif: checked,
    });
  } else {
    // Si l'événement provient d'un autre champ, mettez à jour le state normalement
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  };

  const handleCloseConfirmationDialog = () => {
    setSelectedRowForDeletion(null);
    setOpenConfirmationDialog(false);
  };

  const handleOpenConfirmationDialog = (row) => {
    setSelectedRowForDeletion(row);
    setOpenConfirmationDialog(true);
  };

  const handleConfirmDelete = async () => {
    console.log('ID du row à supprimer :', selectedRowForDeletion.id);
    const userId = selectedRowForDeletion.id; // Remplacez par l'ID de la catégorie que vous souhaitez supprimer

    try {
        const response = await axios.delete(`${API_URL}/deleteUser/${userId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        });

        window.location.href = `${window.location.href}?uploadSuccess=true`;

    } catch (error) {
        if (error.response) {
        // La requête a été effectuée et le serveur a répondu avec un code d'erreur
        console.error('Erreur de réponse du serveur:', error.response.data);
        } else if (error.request) {
        // La requête a été effectuée mais aucune réponse n'a été reçue
        console.error('Aucune réponse du serveur:', error.request);
        } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur de configuration de la requête:', error.message);
        }
    }

    handleCloseConfirmationDialog();
  };

  const handleSubmit = async() => {

    setTimeout(() => {
      setHasError(false);
      setErrorMessage("");
    }, 5000);


    if(formValues.nom || formValues.email || formValues.selectedCompte ){
      
      // Handle form submission, e.g., make an API call
      const randomPassword = Math.floor(100000 + Math.random() * 900000).toString();

      console.log(typeof(randomPassword));
  
      // Update the form values, including the generated password
      setFormValues({
        ...formValues,
        pass: randomPassword,
      });
      try {
        setIsSubmitting(true); 
        // Make an HTTP POST request to your server
  
        const data={
          nom_utilisateur: formValues.nom,
          email: formValues.email ,
          typeCompte: formValues.selectedCompte ,
          motDePasse: formValues.pass ,
        }
  
        const response = await axios.post(`${API_URL}/user`, data );
        handleCloseModal();
        handleOpenModalPass();
      } catch (error) {
          setHasError(true);
          handleCloseModal();
          setErrorMessage("Erreur d'enregistrement")
        console.error('Error submitting form:', error);
      }finally {
        setIsSubmitting(false); // Assurez-vous de mettre à jour l'état, que la soumission réussisse ou échoue
      }
      // Close the modal
      handleCloseModal();
    }else{
      handleCloseModal();
      setHasError(true);
      setErrorMessage("Verifier les informations")
    }
   
  };

  const handleUpdate = async () => {
    const data={
      nom_utilisateur: editedNom,
      email: editedEmail ,
      actif: formValues.actif
    }
    console.log(data);
    try {
      const userId = selectedUser.id; // Supposons que vous avez l'ID de l'utilisateur sélectionné
      const response = await axios.put(`${API_URL}/editUser/${userId}`, data);
      
      // Traitez la réponse, par exemple :
      handleClosesModal();
      window.location.href = `${window.location.href}?uploadSuccess=true`;
      
      // Fermez le modal ou effectuez d'autres actions nécessaires après la mise à jour
      handleCloseModal();
    } catch (error) {
      // Gérez les erreurs ici
      setHasError(true);
      setErrorMessage("Verifier les informations")
      handleClosesModal();
      console.error('Erreur lors de la mise à jour de l\'utilisateur:', error);
    }
   
  };

  const handleDetail = async(row) =>{
    navigate(`/detailUser/${row.id}`)
  }
  



  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {hasError && <AlertComponent message={errorMessage} type="danger" />}
      <div className='ml-5 flex-row justify-between '>
          
          <h1 className='font-bold text-2xl' >Liste des Utilisateurs</h1>
          <Button onClick={handleOpenModal} variant="contained">Ajouter</Button>
          <div className='mt-3' >
            <TextField
              label="Recherche"
              value={searchText}
              className='w-[300px] '
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
      </div>

        <ToastContainer />
        <TableContainer sx={{ maxHeight: 440 }}>
        {isLoading && <div className='ml-10 mt-20 text-xl font-bold  ' >Chargement en cours...</div>}
        {!isLoading && (
           
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left" // Align left for text columns
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data 
              .filter((row) =>
                  Object.values(row).some(
                    (value) =>
                      typeof value === 'string' &&
                      value.toLowerCase().includes(searchText.toLowerCase())
                  )
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.email}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          className={
                            column.id === 'actif'
                              ? row.actif
                                ? 'text-green-500' // Green for active
                                : 'text-red-500' // Red for inactive
                              : ''
                          }
                        >
                          {column.id === 'actions' ? (
                              <>
                                <IconButton style={{color: "blue"}} onClick={() => handleEdit(row)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton style={{color: "red"}} onClick={() => handleOpenConfirmationDialog(row)}>
                                  <DeleteIcon />
                                </IconButton>
                                {row.typeCompte === 'artistes' && (
                                  <IconButton style={{color: "blue"}} onClick={() => handleDetail(row)}>
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                )}
                              </>
                            ) : column.id === 'actif' ? (
                              row.actif ? 'Actif' : 'Inactif'
                            ) : (
                              row[column.id]
                            )}
                        </TableCell>
                        
                      ))}
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        )}
        </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />


      {/* Modal for adding items */}
    {/* Modal for adding items */}
    <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Ajouter un utilisateur </DialogTitle>
        <DialogContent>
          {/* Form elements */}
          <TextField
            label="Nom "
            name="nom"
            value={formValues.nom}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            fullWidth
            required
            type='email'
            margin="normal"
          />
           {/* Select field with placeholder and search */}
           <div className='mt-3' >
             <Autocomplete
                value={formValues.selectedOption}
                onChange={(event, newValue) => {
                setFormValues({
                    ...formValues,
                    selectedCompte: newValue,
                });
                }}
                options={['admin','artistes']} 
                fullWidth
                renderInput={(params) => (
                <TextField {...params} label="Veuillez sélectionner le compte " variant="outlined" />
                )}
            />
           </div>

     
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? 'Enregistrement en cours...' : 'Ajouter'}
          </Button>
        </DialogActions>
    </Dialog>


    {/* Boîte de dialogue de confirmation */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer cet Utilisateur ?
            Cela va supprimer tous les oeures musicales de l'utilisateur 
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} style={{ color: 'red' }} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog open={opensModal} onClose={handleClosesModal}>
           <DialogTitle>Modifier un utilisateur</DialogTitle>
          <DialogContent>
            <TextField
              label="Nom"
              name="nom"
              value={editedNom}
              onChange={(e) => setEditedNom(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              value={editedEmail}
              onChange={(e) => setEditedEmail(e.target.value)}
              fullWidth
              required
              type="email"
              margin="normal"
            />
            <FormControlLabel
               control={
                <Switch
                checked={formValues.actif}
                  onChange={handleInputChange}
                  name="actif"
                  color="primary"
                />
              }
              label={formValues.actif ? 'Actif' : 'Inactif'}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosesModal} color="primary">
              Annuler
            </Button>
            <Button onClick={handleUpdate} color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions> 
      </Dialog>


      {/* modal mot de passe  */}
      <Dialog open={openModalPass} onClose={handleCloseModalPass}>
        <DialogTitle>Mot de passe genere </DialogTitle>
        <DialogContent>
          
            <div>
              <TextField
                disabled
                value={formValues.pass}
                fullWidth
                margin="normal"
              />
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkModal} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

   
    </Paper>
  );
}
