import React, { useState } from 'react';

const AlertComponent = ({ message, type }) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <div className='bg-red-400 border-red-200 text-black font-bold px-4 py-3  relative' role="alert">
          
          <span className="block sm:inline">{message}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              className="fill-current h-6 w-6 text-${type}-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              onClick={handleClose}
            >
              <title>Fermer</title>
              <path d="M14.348 5.652a.5.5 0 0 0-.707 0L10 9.293 5.348 4.64a.5.5 0 1 0-.707.708L9.293 10l-4.652 4.652a.5.5 0 1 0 .707.708L10 10.707l4.652 4.652a.5.5 0 0 0 .707-.708L10.707 10l4.652-4.652a.5.5 0 0 0 0-.708z"></path>
            </svg>
          </span>
        </div>
      )}
    </>
  );
};

export default AlertComponent;
