import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { API_URL } from '../Config/config';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, set } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import AlertComponent from './Alert';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DialogContentText } from '@mui/material';
import { toDate } from 'date-fns';





const columns = [
  { id: 'titre', label: 'Titre', minWidth: 170 },
  { id: 'description', label: 'Description', minWidth: 100 },
  { id: 'annee_sortie', label: 'Annee Sortie', minWidth: 100 },
  { id: 'actif', label: 'Actif', minWidth: 100 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
];


export default function TableMusic() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [artistes, setArtistes] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    titre: '',
    description: '',
    annee_sortie: null,
    fileImage: null,
    fileAudio: null,
    selectedCat: null,
    selectedArt: null,
    // Add more fields as needed
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uploadSuccess = urlParams.get('uploadSuccess');

    if (uploadSuccess) {
      toast.success('Upload réussi');
      setTimeout(() => {
        // Retirez le paramètre de l'URL pour éviter d'afficher le message à chaque rechargement
        window.history.replaceState({}, document.title, window.location.pathname);
      }, 5000); // Disparaît après 5 secondes
    }
  }, []);

  useEffect(() => {
    // Fetch data from your API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/listeMusic`);
        // Assuming your API returns an array of objects similar to your sample data
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }finally {
        // Mettez isLoading à false une fois le chargement terminé
        setIsLoading(false);
    }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/listeCat`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchCategories();
  }, []);


  useEffect(() => {
    const fetchArtiste = async () => {
      try {
        const response = await axios.get(`${API_URL}/listeArtist`);
        setArtistes(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchArtiste();
  }, []);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (row) => {
    // Handle edit action
    navigate(`/musicDetail/${row.id}`)
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

const handleSubmit = async () => {

  setTimeout(() => {
    setHasError(false);
    setErrorMessage("");
  }, 5000);

  const jsDate = toDate(formValues.annee_sortie.$d);

  setIsSubmitting(true); 

  if (jsDate instanceof Date && !isNaN(jsDate)) {
    // Utilisez toISOString() pour formater la date
    const isoDateString = jsDate.toISOString();
    try {
      const formData = new FormData();
      formData.append("image", formValues.fileImage);
      formData.append("audio", formValues.fileAudio);
      formData.append("titre", formValues.titre); // Ajoutez le champ titre
      formData.append("description", formValues.description); // Ajoutez le champ description
      formData.append("categorie", formValues.selectedCat.id); // Ajoutez le champ cadecorieId
      formData.append("id", formValues.selectedArt.id); // Ajoutez le champ cadecorieId
      formData.append("dates", isoDateString);
      // Remplacez 'VOTRE_URL' par l'URL de votre serveur
      await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload réussi');
      handleCloseModal();
      // Redirigez avec le paramètre uploadSuccess=true
      window.location.href = `${window.location.href}?uploadSuccess=true`;
      
    } catch (error) {
      handleCloseModal();
      setHasError(true);
      setErrorMessage("Verifier les informations")
      //console.error('Erreur lors de l\'upload :', error);
    }finally {
      setIsSubmitting(false); // Assurez-vous de mettre à jour l'état, que la soumission réussisse ou échoue
    }
    
  } else {
    console.error("La date est invalide");
  }
 
 
};

  const handleFileChange = (file) => {
    setFormValues({
      ...formValues,
      fileImage: file,
    });
  };

  const handleFileChanges = (file) => {
    setFormValues({
      ...formValues,
      fileAudio: file,
    });
  };

  const handleOpenConfirmationDialog = (row) => {
    setSelectedRowForDeletion(row);
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setSelectedRowForDeletion(null);
    setOpenConfirmationDialog(false);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {hasError && <AlertComponent message={errorMessage} type="danger" />}
        <div className='ml-5 flex-row justify-between '>
          
          <h1 className='font-bold text-2xl' >Liste des Musiques</h1>
          <Button onClick={handleOpenModal} variant="contained">Ajouter une chanson </Button>
          <div className='mt-3' >
            <TextField
              label="Recherche"
              value={searchText}
              className='w-[300px] '
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <ToastContainer />
      <TableContainer sx={{ maxHeight: 440 }}>
        {isLoading && <div className='ml-10 mt-20 text-xl font-bold  ' >Chargement en cours...</div>}
        {!isLoading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left" // Align left for text columns
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {data
              .filter((row) =>
                Object.values(row).some(
                  (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
                )
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.email}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left"
                        className={
                          column.id === 'actif'
                            ? row.actif
                              ? 'text-green-500' // Green for active
                              : 'text-red-500' // Red for inactive
                            : ''
                        }
                      >
                        {column.id === 'actions' ? (
                            <>
                              <IconButton style={{color: "blue"}} onClick={() => handleEdit(row)}>
                                <RemoveRedEyeIcon />
                              </IconButton>
                              <IconButton style={{color: "red"}} onClick={() => handleOpenConfirmationDialog(row)}>
                                <DeleteIcon />
                              </IconButton>
                            </>
                          ) : column.id === 'actif' ? (
                            row.actif ? 'Actif' : 'Inactif'
                          ) : column.id === 'annee_sortie' ? (
                            // Format the date using date-fns
                            format(new Date(row[column.id]), 'dd MMMM yyyy')
                          ) : (
                            row[column.id]
                          )}
                      </TableCell>
                      
                    ))}
                  </TableRow>
                );
              })
            }
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal for adding items */}
      <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Ajouter un titre </DialogTitle>
          <DialogContent>
            {/* Form elements */}

            <TextField
              label="Titre"
              name="titre"
              value={formValues.titre}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Description"
              name="description"
              value={formValues.description}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            {/* Select field with placeholder and search */}
            <div className='mt-3' >
                  <Autocomplete
                      value={formValues.selectedCat}
                      onChange={(event, newValue) => {
                          setFormValues({
                              ...formValues,
                              selectedCat: newValue,
                          });
                      }}
                      options={categories} 
                      getOptionLabel={(option) => option.nom_categories}
                      fullWidth
                      renderInput={(params) => (
                          <TextField {...params} label="Veuillez sélectionner une categorie" variant="outlined" />
                      )}
                  />
            </div>

            <div className='mt-3' >
              <Autocomplete
                  value={formValues.selectedArt}
                  onChange={(event, newValue) => {
                      setFormValues({
                          ...formValues,
                          selectedArt: newValue,
                      });
                  }}
                  options={artistes} 
                  getOptionLabel={(option) => option.nom_utilisateur}
                  fullWidth
                  renderInput={(params) => (
                      <TextField {...params} label="Veuillez sélectionner l'artiste " variant="outlined" />
                  )}
              />
            </div>
        

              {/* Date picker */}
              <div className='mt-3' >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                          label="Date de sortie"
                          inputFormat="MM/dd/yyyy"
                          value={formValues.annee_sortie}
                          onChange={(newDate) =>
                              setFormValues({
                              ...formValues,
                              annee_sortie: newDate,
                              })
                          }
                          renderInput={(params) => <TextField {...params} />}
                      />
                  </LocalizationProvider>
              </div>
          
              <div className='mt-3' >
                  <InputLabel htmlFor="image-input" shrink>
                      Veuillez sélectionner une image
                  </InputLabel>
                <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .gif"
                      onChange={(e) => handleFileChange(e.target.files[0])}
                  />
              </div>

              <div className='mt-3' >
                  <InputLabel htmlFor="image-input" shrink>
                      Veuillez sélectionner le fichier audio
                  </InputLabel>
                <input
                      type="file"
                      accept="audio/mpeg, audio/wav, audio/mp4, audio/mp4, audio/ogg"
                      onChange={(e) => handleFileChanges(e.target.files[0])}
                  />
              </div>

          
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Annuler
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting} >
              {isSubmitting ? 'Enregistrement en cours...' : 'Ajouter'}
            </Button>
          </DialogActions>
      </Dialog>

      {/* Boîte de dialogue de confirmation */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer cet titre ?
            Cela va supprimer tous le titre en question dans la base. 
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Annuler
          </Button>
          <Button  style={{ color: 'red' }} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>


    </Paper>
  );
}
