import React from 'react'
import Sidebar from '../Components/Sidebar'
import AppBars from '../Components/AppBar'
import Box from '@mui/material/Box';
import DateTables from '../Components/DataTable';

function User() {
  return (
    <>
    <AppBars />
    <Box height={70} />
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DateTables />
      </Box>
        
    </Box>

</>
  )
}

export default User