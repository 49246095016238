import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { API_URL } from '../Config/config';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate,Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';;

const columns = [
  { id: 'nom_categories', label: 'Nom', minWidth: 170 },
  { id: 'actif', label: 'Etat', minWidth: 100 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
];

export default function CatTables() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);

  const [openModal, setOpenModal] = React.useState(false);
  const [newCategory, setNewCategory] = React.useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = React.useState(null);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [editedCategory, setEditedCategory] = React.useState('');
  const [selectedRowForEdit, setSelectedRowForEdit] = React.useState(null);
  const [isCategoryActive, setIsCategoryActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uploadSuccess = urlParams.get('uploadSuccess');

    if (uploadSuccess) {
      toast.success('Operation effectue avec success');
      setTimeout(() => {
        // Retirez le paramètre de l'URL pour éviter d'afficher le message à chaque rechargement
        window.history.replaceState({}, document.title, window.location.pathname);
      }, 5000); // Disparaît après 5 secondes
    }
  }, []);

  useEffect(() => {
    // Fetch data from your API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/listeCat`);
        // Assuming your API returns an array of objects similar to your sample data
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Mettez isLoading à false une fois le chargement terminé
        setIsLoading(false);
    }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (row) => {
    handleOpenEditModal(row);
  };
  


  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddCategory = async () => {
    try {
      setIsSubmitting(true); 
      // Assurez-vous que votre serveur accepte les données au format JSON
      const response = await axios.post(`${API_URL}/cat`, {
        nom_categories: newCategory,
        // Ajoutez d'autres propriétés si nécessaire
      });

      if (response.status === 200) {

        window.location.href = `${window.location.href}?uploadSuccess=true`;
      }

      // Ajoutez ici la logique pour gérer la réponse du serveur
      handleCloseModal();
    } catch (error) {
      console.log('Erreur lors de l\'ajout de la catégorie :', error);
    }finally {
      setIsSubmitting(false); // Assurez-vous de mettre à jour l'état, que la soumission réussisse ou échoue
    }
  };

  const handleOpenConfirmationDialog = (row) => {
    setSelectedRowForDeletion(row);
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setSelectedRowForDeletion(null);
    setOpenConfirmationDialog(false);
  };

  const handleConfirmDelete = async () => {
    console.log('ID du row à supprimer :', selectedRowForDeletion.id);
    const categoryId = selectedRowForDeletion.id; // Remplacez par l'ID de la catégorie que vous souhaitez supprimer

    try {
        const response = await axios.delete(`${API_URL}/deleteCat/${categoryId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        });

        console.log(response.data.message); // Affichez le message de succès côté client
        window.location.href = `${window.location.href}?uploadSuccess=true`;

    } catch (error) {
        if (error.response) {
        // La requête a été effectuée et le serveur a répondu avec un code d'erreur
        console.error('Erreur de réponse du serveur:', error.response.data);
        } else if (error.request) {
        // La requête a été effectuée mais aucune réponse n'a été reçue
        console.error('Aucune réponse du serveur:', error.request);
        } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur de configuration de la requête:', error.message);
        }
    }

    handleCloseConfirmationDialog();
  };


  const handleOpenEditModal = (row) => {
    setSelectedRowForEdit(row);
    setEditedCategory(row.nom_categories);
    setIsCategoryActive(row.actif);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedRowForEdit(null);
    setEditedCategory('');
    setIsCategoryActive(false);
    setOpenEditModal(false);
  };
  
  const handleUpdateCategory = async () => {
    const updatedData = {
        id: selectedRowForEdit.id, // assuming there is an 'id' property
        nom_categories: editedCategory,
        actif: isCategoryActive,
        // add other properties as needed
      };
      console.log(updatedData)
      setOpenEditModal(false);
  };
  




  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div className='ml-5 mb-5 flex-row justify-between ' >
          <h1 className='font-bold text-2xl ' >Liste Categorie </h1>
          <Button onClick={handleOpenModal} variant="contained"> Ajouter </Button>
          <div className='mt-3' >
            <TextField
              label="Recherche"
              value={searchText}
              className='w-[300px] '
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <ToastContainer />
      <TableContainer sx={{ maxHeight: 440 }}>
        {isLoading && <div className='ml-10 mt-20 text-xl font-bold  ' >Chargement en cours...</div>}
        {!isLoading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
              .filter((row) =>
                  Object.values(row).some(
                    (value) =>
                      typeof value === 'string' &&
                      value.toLowerCase().includes(searchText.toLowerCase())
                  )
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left"
                        className={
                          column.id === 'actif'
                            ? row.actif
                              ? 'text-green-500'
                              : 'text-red-500'
                            : ''
                        }
                      >
                        {column.id === 'actions' ? (
                          <>
                            <IconButton style={{color:"blue"}}  onClick={() => handleEdit(row)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton style={{color:"red"}} onClick={() => handleOpenConfirmationDialog(row)}>
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ) : column.id === 'actif' ? (
                          row.actif ? 'Actif' : 'Inactif'
                        ) : (
                          <Link to={`/catDetail/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                              {row[column.id]}
                          </Link>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
         )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
  
      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ 
            position: 'absolute',
            width: 400,
            height: 200,
            bgcolor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 4,
        }}>
           <div className='items-center ml-10 mt-5 mb-5 ' >
                <h1 className='font-bold text-xl' > Ajouter une nouvelle categorie </h1>
           </div>
           <div className='ml-14' >
                <TextField
                    label="Nom de la catégorie"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    sx={{ width: '90%' }}
                />
           </div>
           <div className='ml-32' >
                <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleAddCategory}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Enregistrement en cours...' : 'Ajouter'}
                </Button>
           </div>
        </Box>
      </Modal>
      

      {/* Modal for Editing */}
    <Modal
      open={openEditModal}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-edit-title"
      aria-describedby="modal-edit-description"
    >
      <Box
        sx={{
          position: 'absolute',
          width: 400,
          height: 250, // Increased height to accommodate the Switch
          bgcolor: 'white',
          border: '2px solid #000',
          boxShadow: 24,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 4,
        }}
      >
        <div className="items-center ml-10 mt-5 mb-5">
          <h1 className="font-bold text-xl">Modifier la catégorie</h1>
        </div>
        <div className="ml-14">
          <TextField
            label="Nom de la catégorie"
            value={editedCategory}
            onChange={(e) => setEditedCategory(e.target.value)}
            sx={{ width: '90%' }}
          />
        </div>
        <div className="ml-14 mt-3">
          <FormControlLabel
            control={
              <Switch
                checked={isCategoryActive}
                onChange={() => setIsCategoryActive(!isCategoryActive)}
                color={isCategoryActive ? 'success' : 'default'}
              />
            }
            label="Catégorie active"
          />
        </div>
        <div className="ml-32 mt-3">
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleUpdateCategory}
          >
            Modifier
          </Button>
        </div>
      </Box>
    </Modal>





    {/* Boîte de dialogue de confirmation */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer cette catégorie ?
            Cela va supprimer tous les oeures musicales contenu dans cette categorie 
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} style={{ color: 'red' }} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>



    </Paper>
  );
}
