import React from 'react'
import Box from '@mui/material/Box';
import Sidebar from '../Components/Sidebar';
import AppBars from '../Components/AppBar';
import TablePub from '../Components/TablePub';


function Pub() {
  return (
    <>
    <AppBars />
    <Box height={70} />
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <TablePub />
      </Box>
       
    </Box>

</>
  )
}

export default Pub