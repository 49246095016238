import { Paper, TableContainer } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../Config/config';

function DetailMusic() {
    const { id } = useParams(); 
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/deailSon/${id}`);
                setData(response.data[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const urlAudio = data.fichier ? `${API_URL}/uploads/${data.fichier}` : '';
    const urlImage = data.photo ? `${API_URL}/uploads/${data.photo}` : '';

    return (
        <>
            <div className='ml-20 items-center'>
                <img
                    src={urlImage}
                    alt={`Image de l'oeuvre ${data.titre}`}
                    className='h-[200px] w-[300px] '
                />
                <h1 className='font-bold text-2xl'>Information sur le titre</h1>

                {data.fichier && (
                    <>
                    <p>URL audio: {urlAudio}</p>
                    <audio controls onError={(e) => console.error('Error loading audio:', e)}>
                        <source src={urlAudio} type='audio/*' />
                        Votre navigateur ne prend pas en charge l'élément audio.
                    </audio>
                </>
                )}
            </div>
        </>
    );
}

export default DetailMusic;
