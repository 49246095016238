import React from 'react'
import Box from '@mui/material/Box';
import Sidebar from '../Components/Sidebar';
import AppBars from '../Components/AppBar';
import TableMusic from '../Components/TableMusique';

function Musique() {
  return (
    <>
    <AppBars />
    <Box height={70} />
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <TableMusic /> 
      </Box>
        
    </Box>

</>
  )
}

export default Musique