import React,{ useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import Home from './Page/Login';
import User from './Page/User';
import Categorie from './Page/Categories';
import Musique from './Page/Musique';
import Accueil from './Page/Accueil';
import Dashbord from './Components/Dashbord';
import { jwtDecode } from 'jwt-decode'; // Assurez-vous d'installer cette dépendance : npm install jwt-decode
import PageNotFound from './Page/PageNotFound';
import DetailsCat from './Page/DetailsCat';
import MusicDeatail from './Page/MusicDeatail';
import Pub from './Page/Pub';
import DeatailsUser from './Page/DeatailsUser';
import Privacy from './Page/Privacy';
import DeleteDonnee from './Page/DeleteDonnee';
import DeleteCompte from './Page/DeleteCompte';

// Fonction pour vérifier l'authentification de l'utilisateur
const isAuthenticateds = () => {
  // Récupère le token du localStorage
  const token = localStorage.getItem('token');


  // Vérifie si le token est présent
  if (token) {
    try {
      // Essaye de décoder le token
      const decodedToken = jwtDecode(token);

      // Vérifie si le token est expiré
      const isTokenExpired = decodedToken.exp * 1000 > Date.now();
      // Retourne true si le token n'est pas expiré, sinon false
      return isTokenExpired;
    } catch (error) {
      // En cas d'erreur lors du décodage du token, considérez l'utilisateur comme non authentifié
      return false;
    }
  }

  // Retourne false si le token n'est pas présent
  return false;
};

// Composant de protection pour les routes
const ProtectedRoute = ({ element, path }) => {
  const [isAuthenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    // Appelle la fonction isAuthenticateds et met à jour l'état d'authentification en conséquence
    const isAuthenticatedResult = isAuthenticateds();
    setAuthenticated(isAuthenticatedResult);
    console.log(isAuthenticatedResult);
  }, []); // Le tableau vide [] signifie que le useEffect s'exécute une seule fois après le rendu initial

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Remplacez ceci par votre composant de chargement
  }

  return isAuthenticated ? element : <Navigate to="/" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/demande-de-suppression-donnees" element={<DeleteDonnee />} />
        <Route path="/compte-suppression " element={<DeleteCompte />} />
        {/* Utilisez ProtectedRoute au lieu de Route pour les routes protégées */}
        <Route path="/dash" element={<ProtectedRoute element={<Dashbord />} />} />
        <Route path="/user" element={<ProtectedRoute element={<User />} />} />
        <Route path="/cat" element={<ProtectedRoute element={<Categorie />} />} />
        <Route path="/music" element={<ProtectedRoute element={<Musique />} />} />
        <Route path="/pub" element={<ProtectedRoute element={<Pub />} />} />
        <Route path="/catDetail/:id" element={<ProtectedRoute element={<DetailsCat />} />} />
        <Route path="/musicDetail/:id" element={<ProtectedRoute element={<MusicDeatail />} />} />
        <Route path="/detailUser/:id" element={<ProtectedRoute element={<DeatailsUser />} />} />
        <Route path="*" element={<PageNotFound />} /> {/* Ajoutez cette ligne */}
      </Routes>
    </Router>
  );
}

export default App;
