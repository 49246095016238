import React from 'react'
import Sidebar from './Sidebar'
import Box from '@mui/material/Box';
import AppBars from './AppBar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Person2Icon from '@mui/icons-material/Person2';
import StyleIcon from '@mui/icons-material/Style';
import { BarChart } from '@mui/icons-material';
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut,Bar, Line } from "react-chartjs-2";
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function Dashbord() {
  return (
   <>
        <AppBars />
        <Box height={50} />
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Stack spacing={2} direction="row" >
                            <Card sx={{ minWidth: 49 + "%", height: 140 }}>
                                
                                <CardContent>
                                    <div>
                                        <StyleIcon />
                                    </div>
                                    <Typography gutterBottom variant="h5" component="div">
                                        900
                                    </Typography>
                                    <Typography variant="body2" sx={{color: "black"}} color="text.secondary">
                                        Totals Categories
                                    </Typography>
                                </CardContent>
                                
                            </Card>
                            <Card sx={{ minWidth: 49 + "%", height: 140 }}>
                                
                                <CardContent>
                                    <Typography gutterBottom variant="h5"  component="div">
                                    Lizard
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                    species, ranging across all continents except Antarctica
                                    </Typography>
                                </CardContent>
                                
                            </Card>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack spacing={2}  >
                            <Card sx={{ maxWidth: 345 }}>
                                
                                <CardContent>
                                    <Stack spacing={2} direction="row"  >
                                        <Person2Icon />
                                        <div>
                                            <span> 250 </span>
                                            <span> Totals Utilisateurs </span>
                                        </div>
                                    </Stack>
                                    
                                    
                                </CardContent>
                                
                            </Card>
                            <Card sx={{ maxWidth: 345 }}>
                                
                                <CardContent>
                                    <Stack spacing={2} direction="row"  >
                                        <Person2Icon />
                                        <div>
                                            <span> 250 </span>
                                            <span> Totals Artistes </span>
                                        </div>
                                    </Stack>
                                    
                                </CardContent>
                                
                            </Card>
                        </Stack>
                    </Grid>
                    
                </Grid>
                <Box height={10} />

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                            <Card sx={{ height: 65 + "vh" }}>
                                
                                <CardContent>
                                    <Bar
                                        data={{
                                            labels: ['Jun', 'Jul', 'Aug'],
                                            datasets: [
                                            {
                                                id: 1,
                                                label: 'Loss',
                                                data: [5, 6, 7],
                                            },
                                            {
                                                id: 2,
                                                label: 'test',
                                                data: [3, 2, 1],
                                            },
                                            ],
                                            
                                        }}
                                        
                                    />
                                </CardContent>
                                
                            </Card>
                    </Grid>
                    
                    <Grid item xs={4}>
                            <Card sx={{ height: 65 + "vh", maxWidth: 345 }}>
                                
                                <CardContent>
                                   
                                    <div className='mt-8' >
                                        <Doughnut
                                            data={{
                                                labels: ['Jun', 'Jul', 'Aug'],
                                                datasets: [
                                                {
                                                    id: 1,
                                                    label: 'Loss',
                                                    data: [5, 6, 7],
                                                },
                                                {
                                                    id: 2,
                                                    label: 'test',
                                                    data: [3, 2, 1],
                                                },
                                                ],
                                                
                                            }}
                                            
                                        />
                                    </div>
                                    
                                </CardContent>
                                
                            </Card>
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Box>
   
   </>
  )
}

export default Dashbord